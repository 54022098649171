import path from 'path';
import find from 'lodash/find';
import uuid from 'uuid';
import qs from 'qs';
import includes from 'lodash/includes';
import omit from 'lodash/omit';
import {resolve as urlResolve} from 'url';
import {fetch} from './api/fetch';
import {
  CertificateFileExtension,
  DocumentFileExtensions, GalleryCollapsedViewMaxItems,
  ImageFileExtensions, ItemType,
  Modes,
  Packages,
  ReactSettings,
  RegRedirectConstants,
  Tenants
} from './Constants';
import authorizationStore from '../common/api/authorizationStore';
import RequestQuoteConfiguration from './config/RequestQuoteConfiguration.json';
import SurveyConfiguration from './config/SurveyConfiguration.json';
import DiscoverConfiguration from './config/DiscoverConfiguration.json';

const filterFieldErrors = (fieldErrors, fieldNameRegex) => {
  const errors = [];
  Object.keys(fieldErrors).forEach((key) => {
    if (fieldNameRegex !== undefined && Object.prototype.hasOwnProperty.call(fieldErrors, key) && key.match(fieldNameRegex)) {
      fieldErrors[key].forEach(errorMessage => {
        if (!errors.find(x => x === errorMessage)) {
          errors.push(errorMessage);
        }
      })
    }
  });
  return errors;
};

const constructErrorFieldMessageMapFromGraphQlResponse = (graphqlResponse, errorTranslations, ignorableError) =>
  graphqlResponse.errors.reduce((acc, cur) => {
    if (cur.extensions.code === ignorableError) return acc;
    const result = Object.assign({}, acc);
    const fieldName = cur.extensions.code.split('_')[0].split('.')[0];
    const fieldErrorTypeTokens = cur.extensions.code.split('_');
    fieldErrorTypeTokens.shift();
    if (!Object.prototype.hasOwnProperty.call(result, fieldName)) {
      result[fieldName] = [];
    }
    result[fieldName].push(errorTranslations[`${fieldName}_${fieldErrorTypeTokens.join('_')}`]);
    return result;
  }, {});

const captureResponseCodeAsError = (graphqlResponse, errorTranslations, errorToBeCaptured) => {
  const errorCodesReceived = graphqlResponse.errors.map(err => err.extensions.code);
  return (includes(errorCodesReceived, errorToBeCaptured)) ? [errorTranslations[errorToBeCaptured]] : [];
};

const isRxAdmin = mode => mode === Modes.private;
const isParticipantBase = (mode, tenants) => tenants === Tenants.participants || mode === Modes.mixed;

const setLocationHref = (url) => {
  window.location.href = url;
};

const getReactSettings = () => global.window[ReactSettings.artistsDirectory]
    || global.window[ReactSettings.artistsDetails]
    || global.window[ReactSettings.productsDirectory]
    || global.window[ReactSettings.productDetails]
    || global.window[ReactSettings.exhibitorHub]
    || global.window[ReactSettings.taskList]
    || global.window[ReactSettings.ManageAdminRoles]
    || global.window[ReactSettings.default]
    || global.window[ReactSettings.mixpanel];

const getCurrentEnvironment = () => {

  const environment = global.window ? getReactSettings().environment : global.process.env.environment;
  if(!environment) return 'prod';
  return environment;
};

const isDevEnvironment = environment => environment === 'localAgainstDevPkce' || environment === 'localAgainstDev'
  || environment === 'dev' || environment === 'localAgainstShow';

const isShowEnvironment = environment => environment === 'show' ;

const getLocationOrigin = () => window.location.origin;

const getLocationQueryParam = () => global.window && global.window.location.search;

const reloadLocation = (e) => {
  e.preventDefault();
  e.stopPropagation();
  window.location.reload(true);
};

const getLocationHref = () => window.location.href;

const getResizedImage = (url, device, size) => {
  const extName = path.extname(url);
  const fileName =
    extName === '.gif'
      ? `${path.basename(url, extName)}.png`
      : path.basename(url);
  return urlResolve(url, path.join(`${device}-${size}`, fileName));
};

const getResizedProductImage = (url, device, size, displayPage) => {
  const extName = path.extname(url);
  const fileName =
    extName === '.gif'
      ? `${path.basename(url, extName)}.png`
      : path.basename(url);
  return urlResolve(url, path.join(`${device}-${size}-${displayPage}`, fileName));
};

const poll = url => new Promise((resolve, reject) => {
  const firstRetryAfterMs = 200;
  const increaseWaitByFactor = 1.4;
  let times = 15;
  const p = waitTimeInMs => fetch(url, { method: 'HEAD' }).then((r) => {
    times -= 1;
    if (r.ok) {
      resolve('ok');
    } else if (times === 0) {
      reject(new Error('timeout'));
    } else {
      setTimeout(p, waitTimeInMs, waitTimeInMs * increaseWaitByFactor);
    }
  });
  p(firstRetryAfterMs);
});

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};

const hasImageExtension = (fileName) => {
  const extension = fileName.toLowerCase().split('.').pop();
  return ImageFileExtensions.includes(extension);
};

const hasPdfExtension = (fileName) => {
  const extension = fileName.toLowerCase().split('.').pop();
  return DocumentFileExtensions.includes(extension);
};

const hasCertificateExtension = (fileName) => {
  const extension = fileName.toLowerCase().split('.').pop();
  return CertificateFileExtension === extension;
};

const formatBytes = (bytes, decimals = 0) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const convertedBytesInSize = (bytes / (k ** i)).toFixed(decimals);

  return `${convertedBytesInSize} ${sizes[i]}`;
};

const serviceResultHasError = (result) => {
  const { fieldErrors, errors, mustLogIn } = result;
  return (fieldErrors != null && Object.keys(fieldErrors).length !== 0) || errors?.length !== 0 || mustLogIn;
};

const getTranslatedOrDefault = (translations, key) => (translations[key] ? translations[key] : key);

const constructErrorsFromServiceResult = (result, errorTranslations) => {
  if (result.mustLogIn) {
    return [];
  } else if (result.errors?.length > 0) {
    return result.errors;
  } else if (result.errorResponse?.length > 0) {
    return result.errorResponse.map(err => getTranslatedOrDefault(errorTranslations, err.extensions.code));
  }

  return [];
};

const commaSeparateElements = elements => elements.reduce((prev, curr) => [prev, ', ', curr]);

const findByLocale = (multilingual, locale) => {
  const lowerCaseLocale = locale.toLowerCase();
  return find(multilingual, o => o.locale.toLowerCase() === lowerCaseLocale);
};

const getLanguageCodeFromLocale = locale => locale.split('-')[0].toLowerCase();

const getResourceIdFromLocation = (location, idPrefix) => {
  const regex = new RegExp('/('.concat(idPrefix).concat('-.*)/'));
  return regex.exec(location)[1];
};

const constructSSOUrl = (idpUrl, thirdPartyServiceId, eventEditionId, organisationGuid) =>
  `${idpUrl.endsWith('/') ? idpUrl : idpUrl.concat('/')}secure/SingleSignOn/initiate?organisationId=${organisationGuid}` +
      `&thirdPartyServicesId=${thirdPartyServiceId}&eventEditionId=${eventEditionId}`;

const constructLoginEmailUrl = (idpUrl, knownUrl, unknownUrl, eventEditionId, locale) =>
  `${idpUrl.endsWith('/') ? idpUrl : idpUrl.concat('/')}${RegRedirectConstants.LOGIN_EMAIL_PATH}?KnownUrl=${knownUrl}`
          + `&unknownUrl=${unknownUrl}&eventEditionId=${eventEditionId}&locale=${locale}`;

const didRequestFail = result => result.errors.length > 0
  || Object.keys(result.fieldErrors).length > 0
  || result.mustLogIn
  || false;

const getFromLocalStorage = key => global.window.localStorage.getItem(key);

const getShowPlanId = eventEditionId => JSON.parse(global.window.localStorage.getItem('showPlanningIds'))[eventEditionId];

const setValueInLocalStorage = (key, value) => global.window.localStorage.setItem(key, value);

const getFromSessionStorage = key => JSON.parse(global.window.sessionStorage.getItem(key));
const buildHeaders = customHeaders => authorizationStore.getToken()
  .then((token) => {
    const clientId = authorizationStore.getClientId();
    const bearer = token ? { Authorization: `Bearer ${token}` } : {};
    const xClientId = clientId ? { 'X-ClientId': clientId } : {};
    const xCorrelationId = { 'X-CorrelationId': uuid.v4() };
    return Object.assign({ Accept: 'application/json' }, bearer, xClientId, xCorrelationId, { ...customHeaders });
  });

const getPackageName = (packageId) => {
  if (packageId === null || packageId === undefined) {
    return '';
  }
  return Object.keys(Packages).find(key => Packages[key] === packageId);
};

const constructQueryParamWithExistingQueryParams = (paramName, paramValue, currentUrlQueryParamString) => {
  const params = Object.assign({}, omit(qs.parse(currentUrlQueryParamString), [paramName]), {
    [paramName]: paramValue
  });
  return params;
};

const isMSH = () => {
  const authToken = authorizationStore.getToken();
  return authToken.then(token => parseJwt(token).scope.includes('urn:rx:digital:sharer:write'));
};

const isSharerSelf = (organisationGuid) => {
  const authToken = authorizationStore.getToken();
  return authToken.then(token => parseJwt(token)?.rxOrg?.includes(organisationGuid));
};

const getUserId = () => {
  const authToken = authorizationStore.getToken();
  return authToken.then(token => parseJwt(token).sub);
};

const isRFQEnabled = (eventEditionId) => !!getRequestQuoteConfig(eventEditionId);

const isDiscoverEnabled = (eventEditionId) => {
  return !!getDiscoverEventConfig(eventEditionId);
}

const getContactFormCode = (eventEditionId, locale) => {
  const requestQuoteConfig = getRequestQuoteConfig(eventEditionId);
  if(requestQuoteConfig == null){
    return null;
  }
  const formCodeConfig = requestQuoteConfig.formCode;
  return formCodeConfig[locale] === undefined ? formCodeConfig.default : formCodeConfig[locale];
}

const getTertiaryColor = () =>{
  const fallBackColor = '#075065';
  const settings = global.window?.reactSettings || global.window?.reactSettingsProductDetails;
  if(settings?.props?.themes?.brandTertiary){
    return settings.props.themes.brandTertiary;
  }
  return fallBackColor;
}

const getDiscoverConfig = () => {
  let environment = getCurrentEnvironment();
  return DiscoverConfiguration[environment];
}

const getDiscoverEventConfig = (eventEditionId) => {
  let environment = getCurrentEnvironment();
  return DiscoverConfiguration[environment].events.find(e => e.eventEditionId === eventEditionId);
}

const groupBy = function(arr, key, value) {
  return arr.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x[value]);
    return rv;
  }, {});
};

const haveValidCategory = (products, eventEditionId) => {
  const discoverEventConfig = getDiscoverEventConfig(eventEditionId);
  if(!discoverEventConfig.atlasIngredientsCategoryId) {
    return true;
  }
  return !!products.find(p =>
      !!p.filterAttributes && !!p.filterAttributes.find(f =>
          !!f.responses.find(r => r.parentId === discoverEventConfig.atlasIngredientsCategoryId)));
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getSEOFriendlyName = (name) => {
  if(!name)
    return '';
  const regex = /[^\w\s\d\p{L}-]/gu;
  const seoFriendlyName = name.replace(regex, '');
  return encodeURIComponent(seoFriendlyName.toLowerCase());
}

const getSurveyUri = (eventEditionId) => {
  const requestQuoteConfig = getRequestQuoteConfig(eventEditionId);
  if(requestQuoteConfig == null){
    return null;
  }
  return  requestQuoteConfig.surveyUri;
}

const getRequestQuoteConfig = (eventEditionId) => {
  const environment = getCurrentEnvironment();
  const requestQuoteConfigs = RequestQuoteConfiguration[environment];
  if(requestQuoteConfigs != null){
    const requestQuoteConfig = requestQuoteConfigs.find(config => config.eventEditionId === eventEditionId)
    if(requestQuoteConfig){
      return requestQuoteConfig;
    }
  }
  return null;
}

const isEventEditionIdExcludedForSurvey = (eventEditionId) => {
  const environment = getCurrentEnvironment();
  const excludedIds = SurveyConfiguration[environment][0]["eventEditionIdsToExclude"];
  return excludedIds.includes(eventEditionId);
};

const getVerintSurveyUri = (surveyUriKey) => {
  const environment = getCurrentEnvironment();
  const surveyUri = SurveyConfiguration[environment][0][surveyUriKey];
  return surveyUri != null ? surveyUri : null;
}

const saveToSessionStorage = (key, value) =>
    global.window.sessionStorage.setItem(key, JSON.stringify(value));

const isEmptyOrWhitespaces = str => str == null || str.trim() === '';
const isValidRxEventCode = str => str == null || (isFinite(str) && str.length === 5);

const getDocumentThumbnail = (url) => {
  const filename = url.split('/').pop();
  const filenameWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
  const formattedUrl = url.substring(0, url.lastIndexOf('/'));
  return `${formattedUrl}/thumbnail/${filenameWithoutExtension}.jpg`;
};

const removePdfFileNameExtension = (fileName) => {
  if (fileName && fileName.toLowerCase().endsWith('.pdf')) {
    return fileName.slice(0, -4);
  }
  return fileName;
};

const getNoOfItemsToShowInGallery = (type) => {
  if (type === ItemType.Product)
    return window.matchMedia('(max-width: 767px)').matches ?
        GalleryCollapsedViewMaxItems.product.mobile : GalleryCollapsedViewMaxItems.product.desktopOrTablet;
  if (type === ItemType.Document)
    return window.matchMedia('(max-width: 767px)').matches ?
        GalleryCollapsedViewMaxItems.document.mobile : GalleryCollapsedViewMaxItems.document.desktopOrTablet;
  else
    return 0;
};

const sortItems = (type, items) => {
  if (type === ItemType.Product) 
    return [...items].sort((item1, item2) => new Date(item2.lastUpdatedAt) - new Date(item1.lastUpdatedAt));
  if (type === ItemType.Document)
    return [...items].sort((item1, item2) => new Date(item2.createdAt) - new Date(item1.createdAt));
  else 
    return items;
};

module.exports = {
  hasImageExtension,
  hasPdfExtension,
  hasCertificateExtension,
  poll,
  getResizedImage,
  getResizedProductImage,
  filterFieldErrors,
  setLocationHref,
  getCurrentEnvironment,
  isDevEnvironment,
  getLocationOrigin,
  getLocationHref,
  reloadLocation,
  formatBytes,
  isRxAdmin,
  serviceResultHasError,
  getTranslatedOrDefault,
  constructErrorsFromServiceResult,
  commaSeparateElements,
  findByLocale,
  getLanguageCodeFromLocale,
  getFromLocalStorage,
  setValueInLocalStorage,
  getLocationQueryParam,
  getResourceIdFromLocation,
  isParticipantBase,
  constructErrorFieldMessageMapFromGraphQlResponse,
  didRequestFail,
  captureResponseCodeAsError,
  constructSSOUrl,
  constructLoginEmailUrl,
  buildHeaders,
  getPackageName,
  constructQueryParamWithExistingQueryParams,
  parseJwt,
  isMSH,
  isSharerSelf,
  isShowEnvironment,
  getUserId,
  getFromSessionStorage,
  saveToSessionStorage,
  getShowPlanId,
  getReactSettings,
  isRFQEnabled,
  getContactFormCode,
  getSEOFriendlyName,
  isDiscoverEnabled,
  getDiscoverConfig,
  getDiscoverEventConfig,
  groupBy,
  haveValidCategory,
  capitalizeFirstLetter,
  getSurveyUri,
  getTertiaryColor,
  isEmptyOrWhitespaces,
  isValidRxEventCode,
  getVerintSurveyUri,
  getDocumentThumbnail,
  removePdfFileNameExtension,
  getNoOfItemsToShowInGallery,
  sortItems,
  isEventEditionIdExcludedForSurvey
};
